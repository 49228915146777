import * as React from 'react'

import { Unstable_Grid2 as Grid2, Stack, Typography } from '@mui/material'

import { HighlightedText } from '../highlighted-text'

export const AutocompleteOption: React.FC<{
    searchTerm?: string
    option: { id: string; label: string; icon: React.ReactNode; text: string; subtexts: string[] }
}> = ({ searchTerm, option }) => {
    return (
        <Grid2 container minHeight={56} padding={1}>
            <Grid2 xs={1} paddingTop={1}>
                {option.icon}
            </Grid2>
            <Grid2 xs={11} paddingTop={1} paddingLeft={1}>
                <Stack>
                    <Typography variant="body3" color="dark.simple">
                        <HighlightedText highlightedText={searchTerm}>{option.text}</HighlightedText>
                    </Typography>
                    {option.subtexts.length > 0 ? (
                        <Typography variant="body3" color="dark.simple">
                            {option.subtexts.join(' • ')}
                        </Typography>
                    ) : null}
                </Stack>
            </Grid2>
        </Grid2>
    )
}
