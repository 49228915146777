import * as Sentry from '@sentry/nextjs'

import { Session } from 'next-auth'
import { useRouter } from 'next/navigation'
import { useSnackbar } from 'notistack'

import { useSession } from '@/lib/auth/use-session'
import { useTranslation } from '@/lib/i18n'
import { NetworkError } from '@apollo/client/errors'

import { isServerError, isServerParseError } from './utils/error-type-guards'

type HandleNetworkError = (error: NetworkError | undefined) => void

type UseHandleNetworkError = () => {
    handleNetworkError: HandleNetworkError
}

type ErrorContext = {
    session: Session | null
    error?: {
        bodyText?: string
        message?: string
        response?: Response
        result?: Record<string, any> | string
        stack?: string
        statusCode?: number
    }
}

export const useHandleNetworkError: UseHandleNetworkError = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { session } = useSession()
    const { t } = useTranslation('common')
    const router = useRouter()

    const handleNetworkError: HandleNetworkError = (error) => {
        if (!error) {
            return
        }

        // HTTP 401: Authentication is required and has failed or has not yet been provided.
        if ('statusCode' in error && error.statusCode === 401) {
            router.push('/api/auth/signin/keycloak')
            return
        }

        // HTTP 403: Access to the requested resource is forbidden.
        if ('statusCode' in error && error.statusCode === 403) {
            router.push('/no-permission')
            return
        }

        let logMessage = ''
        let errorDetails: ErrorContext['error'] = {
            message: error.message,
            stack: error.stack,
        }

        if (isServerError(error)) {
            logMessage = `[Server Error]: Status ${error.statusCode}, ${error.response.statusText}`
            errorDetails = error

            Sentry.captureException(error, { extra: { session, error: errorDetails } })
        } else if (isServerParseError(error)) {
            logMessage = `[Server Parse Error]: Status ${error.statusCode}, Body ${error.bodyText}`
            errorDetails = error

            Sentry.captureException(error, { extra: { session, error: errorDetails } })
        } else {
            logMessage = `[Network error]: ${error.message}`
        }

        // Log network errors (e.g. 'Failed to fetch'). These errors, which may occur sporadically,
        // do not significantly impact user experience and provide limited diagnostic information.
        // We record them for monitoring while allowing the user to continue.
        console.error(logMessage)

        enqueueSnackbar(t('unexpectedError'), {
            variant: 'customErrorSnackbar',
            data: logMessage,
        })
    }

    return { handleNetworkError }
}
